<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/jsfn.png" alt="" />
      <div class="jsfnB1">云计算</div>
      <div class="jsfnC1">CLOUD COMPUTING</div>
      <div class="jsfnD1">
        &emsp;&emsp;云计算指通过计算机网络(多指因特网)形成的计算能力极强的系统，可存储、集合相关资源并可按需配置，向用户提供个性化服务。<br />
        &emsp;&emsp;现阶段所说的云服务已经不单单是一种分布式计算，而是分布式计算、效用计算、负载均衡、并行计算、网络存储、热备份冗杂和虚拟化等计算机技术混合演进并跃升的结果。
      </div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">消防安全预警平台</div>
        <div class="bodyTitle">
          <div class="product">FIRE SAFETY WARNING PLATFORM</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div>
        <div class="jsfny11">
          <img class="jsfny1" src="@/assets/jsfny1.png" alt="" />
        </div>
        <div class="fontA1">
          &emsp;&emsp;消防安全预警平台运用物联网、云计算、大数据、
          移动互联网等新兴信息技术，
          全面促进信息化与消防业务工作的深度融合、全覆盖的社会火灾防控体系，
          实现“传统消防”向“现代消防”的转变。
        </div>
        <div class="fontA11">
          &emsp;&emsp;通过更早发现、更快处理，将火灾风险和影响降到最低。
        </div>
      </div>
      <div>
        <div class="cpszh">远程扬尘治理平台</div>
        <div class="bodyTitle">
          <div class="product">REMOTE DUST CONTROL</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div class="jsfny11">
        <img class="jsfny1" src="@/assets/jsfny2.png" alt="" />
      </div>
      <div class="fontA1">
        &emsp;&emsp;目前城市雾霾给人民日常生活带来或多或少的影响，如何治理雾霾已提上了各级政府的工作日程。扬尘是其污染的主要原因之一。
      </div>
      <div class="fontA1">
        &emsp;&emsp;远程扬尘治理平台基于云计算、物联网与智能测控技术等服务，提供一站式治理解决方案，然茜科技致力于解决扬尘问题，提升人民的生活质量，降低扬尘对空气的影响，掌握扬尘排放情况。
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.jsfny11 {
  display: flex;
  justify-content: center;
}
.jsfny1 {
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 20px 32px;
  line-height: 40px;
}
.fontA2 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 24px 24px 0 32px;
  line-height: 40px;
}
.jsfnr2 {
  margin: 24px 32px 0 32px;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
</style>
